import { Typography, Container, Grid } from "@mui/material";
import logo from '../assets/bygcom-logo-hvid.svg';


const url = "https://www.bygcom.com";
const linkProfiler = url + "/produkter/profiler/";
const linkVaegelementer = url + "/produkter/vaegelementer/";
const linkKarnapper = url + "/produkter/karnapper/";
const linkFacadesystem = url + "/produkter/facadesystem/";
const linkDaekOgSpaer = url + "/produkter/daek-og-spaer/";
const linkBygningskomponenter = url + "/produkter/bygningskomponenter/";
const linkUnderleverandoer = url + "/produkter/underleverandoer/";
const linkKontakt = url + "/kontakt/";
const linkLeveringsbetingelser = url + "/wp-content/uploads/2023/10/leveringsbetingelser.pdf";
const linkForretningsbetingelser = url + "/wp-content/uploads/2023/10/forretningsbetingelser.pdf";
const linkPrivatlivsOgCookiepolitik = url + "/privatlivs-og-cookiepolitik/";

const Footer = () => {
	return (

		<div style={{ backgroundColor: '#0b2a55', marginTop: 50 }}>
			<Container maxWidth="lg">
				<Grid container spacing={5}>
					<Grid item xs={12} md={3}>
						<Typography variant="body1" style={{ color: 'white', marginTop: 0, marginBottom: 20 }}>
							<a href="https://www.bygcom.com"><img width="100px;" src={logo} alt="logo" /></a>
						</Typography>
						<Typography variant="body1" style={{ color: 'white', marginTop: 30, marginBottom: 20 }}>Bygcom Danmark ApS<br />Hammeren 1, 6715 Tarp<br />CVR. 41169265<br />						</Typography>
					</Grid>
					<Grid item xs={12} md={3}>
						<Typography variant="body1" style={{ color: 'white', marginTop: 0, marginBottom: 20, fontWeight: 700 }}>Produkter</Typography>
						<Typography variant="body1" style={{ color: 'white', marginTop: 0, marginBottom: 20 }}>
							<ul style={{ color: 'white', listStyle: 'none', marginLeft: 0, paddingInlineStart: 0 }} id="menu-1-60379e2" class="footerLinks" data-smartmenus-id="17073047481538052">
								<li class=""><a style={{ color: 'white' }} href={linkProfiler} class="elementor-item">Profiler</a></li>
								<li class=""><a href={linkVaegelementer} class="elementor-item">Vægelementer</a></li>
								<li class=""><a href={linkKarnapper} class="elementor-item">Karnapper</a></li>
								<li class=""><a href={linkFacadesystem} class="elementor-item">Facadesystem</a></li>
								<li class=""><a href={linkDaekOgSpaer} class="elementor-item">Dæk og spær</a></li>
								<li class=""><a href={linkBygningskomponenter} class="elementor-item">Bygningskomponenter</a></li>
								<li class=""><a href={linkUnderleverandoer} class="elementor-item">Underleverandør</a></li>
							</ul>
						</Typography>
					</Grid>
					<Grid item xs={12} md={3}>
						<Typography variant="body1" style={{ color: 'white', marginTop: 0, marginBottom: 20, fontWeight: 700 }}>Information</Typography>
						<Typography variant="body1" style={{ color: 'white', marginTop: 0, marginBottom: 20 }}>
							<ul style={{ color: 'white', listStyle: 'none', marginLeft: 0, paddingInlineStart: 0 }} id="menu-1-55ee0878" class="footerLinks" data-smartmenus-id="17073047481542295">
								<li class=""><a href={linkKontakt} class="elementor-item">Kontakt</a></li>
								<li class=""><a target="_blank" rel="noreferrer" href={linkLeveringsbetingelser} class="elementor-item">Leveringsbetingelser</a></li>
								<li class=""><a target="_blank" rel="noreferrer" href={linkForretningsbetingelser} class="elementor-item">Forretningsbetingelser</a></li>
								<li class=""><a href={linkPrivatlivsOgCookiepolitik} class="elementor-item">Privatlivs- og cookiepolitik</a></li>
							</ul>
						</Typography>
					</Grid>
					<Grid item xs={12} md={3}>
						<Typography variant="body1" style={{ color: 'white', marginTop: 0, marginBottom: 20, fontWeight: 700 }}>Kontakt</Typography>
						<Typography variant="body1" style={{ color: 'white', marginTop: 0, marginBottom: 20 }}>
							<a style={{ color: 'white', textDecoration: 'none' }} href="mailto:info@bygcom.com">info@bygcom.com</a><br />
							<a style={{ color: 'white', textDecoration: 'none' }} href="tel:+4573709002">Tlf. 73 70 90 02</a><br /><br />
							<span>Hverdage: 07:30 – 15:30</span><br />
							<span>Weekend og helligdage: Lukket</span>
						</Typography>

					</Grid>
				</Grid>
			</Container>
		</div>
	)
}

export default Footer;
