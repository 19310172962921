import { useState, useEffect } from "react";
import { Checkbox, FormGroup, FormControlLabel, Grid, ButtonGroup, Button, CircularProgress, Divider } from "@mui/material";
import { Typography, TextField } from "@mui/material";
import { useNavigate, useParams } from 'react-router-dom';

import { getActiveOrder, updateOrderItem, deleteOrderItem, getOrderItemPdf, getOrderItemImage } from "../lib/api";
import ConfirmDialog from "../components/ConfirmDialog";
import ItemCutout from "../components/ItemCutout";

const CalculationEditItem = () => {
	const { panelId } = useParams();
	const navigate = useNavigate();

	const [confirmDialogVisible, setConfirmDialogVisible] = useState(false);
	const [confirmCutoutDeleteVisible, setConfirmCutoutVisible] = useState(false);
	const [selectedCutoutIndex, setSelectedCutoutIndex] = useState(null);
	const [loading, setLoading] = useState(true);
	const [order, setOrder] = useState(null);
	const [orderState, setOrderState] = useState(0);
	const [item, setItem] = useState(null);
	const [a, setA] = useState('');
	const [b, setB] = useState('');
	const [c, setC] = useState('');
	const [d, setD] = useState('');
	const [spacing, setSpacing] = useState('');
	const [assembled, setAssembled] = useState(false);
	const [image, setImage] = useState(null);

	const fields = {
		1: [
			{ id: 'a', label: 'A: Højde i mm', value: a, onChange: setA },
			{ id: 'b', label: 'B: Bredde i mm', value: b, onChange: setB },
		],
		2: [
			{ id: 'a', label: 'A: Højde i mm', value: a, onChange: setA },
			{ id: 'b', label: 'B: Bredde i mm', value: b, onChange: setB },
			{ id: 'c', label: 'C: Højde i mm', value: c, onChange: setC },
		],
		3: [
			{ id: 'a', label: 'A: Højde i mm', value: a, onChange: setA },
			{ id: 'b', label: 'B: Bredde i mm', value: b, onChange: setB },
			{ id: 'c', label: 'C: Højde i mm', value: c, onChange: setC },
		],
		4: [
			{ id: 'a', label: 'A: Højde i mm', value: a, onChange: setA },
			{ id: 'b', label: 'B: Bredde i mm', value: b, onChange: setB },
			{ id: 'c', label: 'C: Højde i mm', value: c, onChange: setC },
			{ id: 'd', label: 'D: Bredde til kip i mm', value: d, onChange: setD },
		],
		100: [
			{ id: 'a', label: 'A: Længde i mm', value: a, onChange: setA },
			{ id: 'b', label: 'B: Bredde i mm', value: b, onChange: setB },
		],
	};

	useEffect(() => {
		async function fetchData() {
			const orderResult = await getActiveOrder();

			if (!orderResult.ok) {
				navigate('/mycalculations');
			} else {
				setOrder(orderResult.data);

				setOrderState(orderResult.data.state);

				if (orderResult.data.items?.length) {
					const activeItem = orderResult.data.items.find(item => item.id === parseInt(panelId));
					if (activeItem === undefined) {
						alert('Elementet findes ikke på denne ordre');
					} else {
						// console.log(activeItem);
						setItem(activeItem);
						setA(activeItem.panels[0].structure.a);
						setB(activeItem.panels[0].structure.b);
						setC(activeItem.panels[0].structure.c);
						setD(activeItem.panels[0].structure.d);
						setSpacing(activeItem.panels[0].structure.spacing);
						setAssembled(activeItem.panels[0].structure.assembled === 1 ? true : false);

						if (activeItem.panels[0].structure.cutouts.length) {
							const imageResult = await getOrderItemImage(orderResult.data.id, activeItem.id);

							if (imageResult.ok) {
								setImage(imageResult.data);
							}
						}

						setLoading(false);
					}
				}
			}
		}

		if (panelId !== undefined) {
			fetchData();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [panelId]);

	const handleUpdate = async (redirect) => {
		let newItem = { ...item };
		newItem.panels[0].structure.a = a;
		newItem.panels[0].structure.b = b;
		newItem.panels[0].structure.c = c;
		newItem.panels[0].structure.d = d;
		newItem.panels[0].structure.spacing = spacing;
		newItem.panels[0].structure.assembled = assembled ? 1 : 0;

		const updateResult = await updateOrderItem(order.id, panelId, newItem);

		if (!updateResult.ok) {
			alert('Der opstod en fejl under opdateringen');
		} else {
			if (redirect) {
				navigate('/order');
			}
		}
	}

	const handleDeleteClose = () => {
		setConfirmDialogVisible(false);
	}

	const handleDelete = async () => {
		setConfirmDialogVisible(true);
	}

	const handleDeleteDo = async () => {
		const deleteResult = await deleteOrderItem(order.id, panelId);

		setConfirmDialogVisible(false);

		if (!deleteResult.ok) {
			alert('Der opstod en fejl under sletningen');
		} else {
			navigate('/order');
		}
	}

	const handleAddCutout = async () => {
		let newItem = { ...item };
		newItem.panels[0].structure.a = a;
		newItem.panels[0].structure.b = b;
		newItem.panels[0].structure.c = c;
		newItem.panels[0].structure.d = d;
		newItem.panels[0].structure.spacing = spacing;
		newItem.panels[0].structure.assembled = assembled ? 1 : 0;

		//add cutout to beginning of array
		newItem.panels[0].structure.cutouts.push({ origin: { x: 0, y: 0 }, size: { x: 0, y: 0 }, note: '' });

		const updateResult = await updateOrderItem(order.id, panelId, newItem);

		if (!updateResult.ok) {
			alert('Der opstod en fejl under opdateringen');
		} else {
			setItem(newItem);
		}
	}

	const handleUpdateCutout = async (cutout, index) => {
		let newItem = { ...item };
		newItem.panels[0].structure.cutouts[index] = cutout;

		setItem(newItem);

		await handleGetOrderItemImage();
	}

	const handleDeleteCutout = async (index) => {
		setSelectedCutoutIndex(index);
		setConfirmCutoutVisible(true);
	}

	const handleDeleteCutoutDo = async () => {
		let newItem = { ...item };
		newItem.panels[0].structure.cutouts.splice(selectedCutoutIndex, 1);

		setItem(newItem);

		await handleGetOrderItemImage();

		handleDeleteCutoutClose();
	}

	const handleDeleteCutoutClose = () => {
		setConfirmCutoutVisible(false);
		setSelectedCutoutIndex(null);
	}

	const handleGetPDF = async () => {
		const pdfResult = await getOrderItemPdf(order.id, panelId);

		if (!pdfResult.ok) {
			alert('Der opstod en fejl under hentningen af PDF');
		} else {
			const linkSource = `data:application/pdf;base64,${pdfResult.data}`;
			const downloadLink = document.createElement('a');
			const fileName = order.id + '.pdf';

			downloadLink.href = linkSource;
			downloadLink.download = fileName;
			downloadLink.click();
		}
	}

	const handleGetOrderItemImage = async () => {
		await handleUpdate(false);

		const imageResult = await getOrderItemImage(order.id, panelId);
		if (imageResult.ok) {
			setImage(imageResult.data);
		}
	}

	const handleFieldChange = (e) => {
		if (e.target.id === 'a') {
			setA(e.target.value);
		} else if (e.target.id === 'b') {
			setB(e.target.value);
		} else if (e.target.id === 'c') {
			setC(e.target.value);
		} else if (e.target.id === 'd') {
			setD(e.target.value);
		}
	}

	return (
		<>
			<Typography variant="h1">Ny beregning - rediger element</Typography>

			{orderState >= 2 ? <Typography style={{ marginBottom: 20, fontWeight: 'bold' }}>Denne ordre er afsendt og kan derfor ikke ændres</Typography> : <></>}

			{!loading ? (
				<>
					<Grid container>
						<Grid item xs={8}>
							<Grid container spacing={3}>
								{fields[item.panels[0].type].map((field) => (
									<Grid item xs={12} key={field.id}>
										<TextField
											id={field.id}
											label={field.label}
											value={field.value}
											variant="outlined"
											onBlur={async () => await handleGetOrderItemImage()}
											onChange={(e) => handleFieldChange(e)}
											style={{ width: 300 }}
											disabled={orderState >= 2}
										/>
									</Grid>
								))}
								<Grid item xs={12}>
									<FormGroup>
										<FormControlLabel control={<Checkbox checked={assembled} onChange={(event) => setAssembled(event.target.checked ? true : false)} />} label="Samlet" />
									</FormGroup>
								</Grid>
								{orderState < 2 ? (
									<Grid item xs={12}>
										<ButtonGroup variant="contained" size="large">
											<Button onClick={() => handleUpdate(true)}>Opdater element</Button>
											<Button onClick={() => handleDelete()}>Slet element</Button>
											<Button onClick={() => handleAddCutout()}>Tilføj udsparring</Button>
											<Button onClick={() => handleGetPDF()}>Hent PDF</Button>
										</ButtonGroup>
									</Grid>
								) : <></>}


								{item.panels[0].structure.cutouts.length ? (
									<>
										<Typography variant="h2">Udsparringer</Typography>

										{item.panels[0].structure.cutouts.map((cutout, index) => (
											<>
												<ItemCutout key={'cutout' + index} cutout={cutout} index={index} handleUpdate={handleUpdateCutout} handleDelete={() => handleDeleteCutout(index)} />
												{index < item.panels[0].structure.cutouts.length - 1 ? <Divider style={{ width: 630, marginTop: 20, marginBottom: 20 }} /> : <></>}
											</>
										))}
									</>
								) : (
									<></>
								)}
							</Grid>
						</Grid>
						<Grid item xs={4}>
							{image && item.panels[0].structure.cutouts.length ? (
								<img src={"data:image/png;base64," + image} alt="Struktur" style={{ maxWidth: '100%' }} />
							) : (
								<img src={"/assets/structuremeassure/" + item.panels[0].type + ".svg"} alt="Struktur" style={{ maxWidth: '100%' }} />
							)}
						</Grid>
					</Grid>

					<ConfirmDialog
						visible={confirmDialogVisible}
						title="Slet element"
						text="Er du sikker på at du vil slette dette element?"
						handleOk={() => handleDeleteDo()}
						handleCancel={() => handleDeleteClose()}
					/>

					<ConfirmDialog
						visible={confirmCutoutDeleteVisible}
						title="Slet udsparring"
						text="Er du sikker på at du vil slette denne udsparring?"
						handleOk={() => handleDeleteCutoutDo()}
						handleCancel={() => handleDeleteCutoutClose()}
					/>
				</>
			) : (
				<CircularProgress />
			)}
		</>
	)
}

export default CalculationEditItem;