import { Container, AppBar, Toolbar, MenuItem, Typography, Button } from '@mui/material';
import { Routes, Route, useNavigate } from 'react-router-dom';
import cookie from 'react-cookies';

import useGlobalState from "../hooks/useGlobalState";
import logo from '../assets/bygcom-logo-hvid.svg';
import Frontpage from './Frontpage';
import Calculation from './Calculation';
import CalculationEditItem from './CalculationEditItem';
import Order from './Order';
import Login from './Login';
import ForgotPassword from './ForgotPassword';
import CreateUser from './CreateUser';
import ConfirmUser from './ConfirmUser';
import Administration from './Administration';
import AdministrationOrgEdit from './AdministrationOrgEdit';
import AdministrationUserAdd from './AdministrationUserAdd';
import AdministrationUserEdit from './AdministrationUserEdit';
import AdministrationOrgAdd from './AdministrationOrgAdd';
import AdministrationImportProduct from './AdministrationImportProduct';
import MyCalculations from './MyCalculations';
import Footer from '../components/Footer';
import RequireAuth from '../helpers/RequireAuth';

const Main = () => {
	const navigate = useNavigate();
	const [state, dispatch] = useGlobalState();

	const handleNav = (page) => {
		switch (page) {
			default:
			case 'frontpage':
				navigate('/');
				break;
			case 'calculation':
				navigate('/calculation');
				break;
			case 'order':
				navigate('/order');
				break;
			case 'login':
				navigate('/login');
				break;
			case 'createuser':
				navigate('/createuser');
				break;
			case 'administration':
				navigate('/administration');
				break;
			case 'mycalculations':
				navigate('/mycalculations');
				break;
		}
	}

	const handleLogout = () => {
		cookie.remove('token');
		cookie.remove('SESSION');

		dispatch({ user: null, orderId: null, userIsAdmin: false, selectedStructure: null, selectedStructureType: null });

		navigate('/login', { replace: true });
	}

	return (
		<>
			<AppBar position="absolute">
				<Container maxWidth="lg">
					<Toolbar disableGutters>
						<Button onClick={() => handleNav('frontpage')}>
							<img src={logo} alt="logo" />
						</Button>
						<MenuItem key="frontpage" onClick={() => handleNav('frontpage')}>
							<Typography textAlign="center">FORSIDE</Typography>
						</MenuItem>

						{state.orderId !== null ? (
							<MenuItem key="order" onClick={() => handleNav('order')}>
								<Typography textAlign="center">AKTIV BEREGNING</Typography>
							</MenuItem>
						) : (
							<MenuItem key="calculation" onClick={() => handleNav('calculation')}>
								<Typography textAlign="center">BEREGNING</Typography>
							</MenuItem>
						)}

						{cookie.load('SESSION') === undefined ? (
							<>
								<MenuItem key="login" onClick={() => handleNav('login')}>
									<Typography textAlign="center">LOG IND</Typography>
								</MenuItem>
								<MenuItem key="createuser" onClick={() => handleNav('createuser')}>
									<Typography textAlign="center">OPRET BRUGER</Typography>
								</MenuItem>
							</>
						) : (
							<>
								<MenuItem key="mycalculations" onClick={() => handleNav('mycalculations')}>
									<Typography textAlign="center">MINE BEREGNINGER</Typography>
								</MenuItem>

								{state.user && state.user.org.id !== 140890100001721 ? (
									<MenuItem key="administration" onClick={() => handleNav('administration')}>
										<Typography textAlign="center">ADMINISTRATION</Typography>
									</MenuItem>
								) : <></>}

								<MenuItem key="logout" onClick={() => handleLogout()}>
									<Typography textAlign="center">LOG UD</Typography>
								</MenuItem>
							</>
						)}
					</Toolbar>
				</Container>
			</AppBar>

			<Toolbar />

			<Container maxWidth="lg">
				<Routes>
					<Route path="/login" element={<Login />}></Route>
					<Route path="/forgotpassword" element={<ForgotPassword />}></Route>
					<Route path="/forgotpassword/:token" element={<ForgotPassword />}></Route>
					<Route path="/createuser" element={<CreateUser />}></Route>
					<Route path="/confirmuser/:token" element={<ConfirmUser />}></Route>
					<Route path="/" element={<Frontpage />} />
					<Route path="/calculation/edititem">
						<Route path=":panelId" element={<RequireAuth><CalculationEditItem /></RequireAuth>} />
					</Route>
					<Route path="/calculation" element={<RequireAuth><Calculation /></RequireAuth>} />
					<Route path="/order" element={<Order />}>
						<Route path=":orderId" element={<RequireAuth><Order /></RequireAuth>} />
					</Route>
					<Route path="/administration" element={<RequireAuth><Administration /></RequireAuth>} />
					<Route path="/administration/org/:orgId/edit" element={<RequireAuth><AdministrationOrgEdit /></RequireAuth>} />
					<Route path="/administration/org/add" element={<RequireAuth><AdministrationOrgAdd /></RequireAuth>} />
					<Route path="/administration/user/add/:orgId" element={<RequireAuth><AdministrationUserAdd /></RequireAuth>} />
					<Route path="/administration/user/:userId/edit" element={<RequireAuth><AdministrationUserEdit /></RequireAuth>} />
					<Route path="/administration/importproducts" element={<RequireAuth><AdministrationImportProduct /></RequireAuth>} />
					<Route path="/mycalculations" element={<RequireAuth><MyCalculations /></RequireAuth>} />
				</Routes>
			</Container>

			<Footer />
		</>
	);
}

export default Main;