import { useState } from "react";
import { TextField, Typography, Grid, Button, ButtonGroup } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { addOrganisation } from "../lib/api";

const AdministrationOrgAdd = () => {
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);
	const [org, setOrg] = useState({});
	const [name, setName] = useState('');
	const [address, setAddress] = useState('');
	const [zip, setZip] = useState('');
	const [city, setCity] = useState('');
	const [discount, setDiscount] = useState('');

	const handleCancel = () => {
		navigate('/administration');
	}

	const handleSave = async () => {
		setLoading(true);

		const newOrg = { ...org };
		newOrg.name = name;
		newOrg.address = address;
		newOrg.zip = zip;
		newOrg.city = city;
		newOrg.extendedFields = { discount };

		const result = await addOrganisation(newOrg);

		if (!result.ok) {
			alert('Der opstod en fejl');
			setLoading(false);
		} else {
			setOrg(result.data);
			setLoading(false);
		}
	}

	return (
		<>
			<Typography variant="h1">Tilføj firma</Typography>

			<Grid container spacing={2}>
				<Grid item xs={12}>
					<TextField
						id="name"
						label="Firma"
						variant="outlined"
						value={name}
						onChange={(e) => setName(e.target.value)}
						style={{ width: 300 }}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						id="address"
						label="Adresse"
						variant="outlined"
						value={address}
						onChange={(e) => setAddress(e.target.value)}
						style={{ width: 300 }}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						id="zip"
						label="Postnummer"
						variant="outlined"
						value={zip}
						onChange={(e) => setZip(e.target.value)}
						style={{ width: 300 }}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						id="city"
						label="By"
						variant="outlined"
						value={city}
						onChange={(e) => setCity(e.target.value)}
						style={{ width: 300 }}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						id="discount"
						label="Rabat"
						variant="outlined"
						value={discount}
						onChange={(e) => setDiscount(e.target.value)}
						style={{ width: 300 }}
					/>
				</Grid>
				<Grid item xs={12}>
					<ButtonGroup variant="contained">
						<Button onClick={() => handleCancel()} disabled={loading}>ANNULER</Button>
						<Button onClick={() => handleSave()} disabled={loading}>GEM</Button>
					</ButtonGroup>
				</Grid>
			</Grid>
		</>
	)
}

export default AdministrationOrgAdd;