import React from 'react';
import ReactDOM from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from "@mui/material/styles";

import './index.css';
import App from './App';
import theme from './theme';
import GlobalStateProvider from './providers/GlobalStateProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<GlobalStateProvider>
				<App />
			</GlobalStateProvider>
		</ThemeProvider>
	</React.StrictMode>
);
