import { useEffect, useState } from "react";
import { CircularProgress, TextField, Typography, Grid, ButtonGroup, Button } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";

import useGlobalState from "../hooks/useGlobalState";
import { getOrganisation, updateOrganisation, deleteOrganisation } from "../lib/api";
import ConfirmDialog from "../components/ConfirmDialog";
import AdministrationOrgUsers from "./AdministrationOrgUsers";

const AdministrationOrgEdit = () => {
	const { orgId } = useParams();
	const navigate = useNavigate();
	const [state] = useGlobalState();

	const [loading, setLoading] = useState(true);
	const [error, setError] = useState('');
	const [confirmOpen, setConfirmOpen] = useState(false);
	const [org, setOrg] = useState({});
	const [name, setName] = useState('');
	const [address, setAddress] = useState('');
	const [zip, setZip] = useState('');
	const [city, setCity] = useState('');
	const [discount, setDiscount] = useState('');

	useEffect(() => {
		async function fetchData() {
			const result = await getOrganisation(orgId);
			if (result.ok) {
				setOrg(result.data);
				setName(result.data.name);
				setAddress(result.data.address);
				setZip(result.data.zip);
				setCity(result.data.city);

				if (result.data.aux?.discount) {
					setDiscount(result.data.aux.discount.replace('.', ','));
				}

				setLoading(false);
			}
		}

		fetchData();
	}, [orgId]);

	const handleCancel = () => {
		navigate('/administration');
	}

	const handleSave = async () => {
		setError('');

		if (name === '') {
			setError('Navn på firma skal udfyldes');
		} else if (state.userIsAdmin && discount !== '' && isNaN(discount.replace(',', '.'))) {
			setError('Rabat skal være et tal');
		} else {
			setLoading(true);

			const newOrg = { ...org };
			newOrg.name = name;
			newOrg.address = address;
			newOrg.zip = zip;
			newOrg.city = city;

			if (state.userIsAdmin) {
				let newAux = { ...org.aux };
				if (discount === '') {
					delete newAux.discount;
				} else {
					newAux.discount = discount.replace(',', '.');
				}
				newOrg.aux = newAux;
			}

			const result = await updateOrganisation(orgId, newOrg);

			if (!result.ok) {
				alert('Der opstod en fejl');
			} else {
				setOrg(result.data);
				setLoading(false);
			}
		}
	}

	const handleDelete = () => {
		setConfirmOpen(true);
	}

	const handleDeleteDo = async () => {
		const deleteResult = await deleteOrganisation(orgId);

		if (!deleteResult.ok) {
			alert('Der opstod en fejl');
		} else {
			navigate('/administration');
		}
	}

	const handleAddUser = () => {
		navigate('/administration/user/add/' + orgId);
	}

	return (
		<>
			{!loading ? (
				<>
					<Typography variant="h1">{org.name}</Typography>

					{error !== '' && <Typography variant="body1" color="error" style={{ marginBottom: 20 }}>{error}</Typography>}

					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TextField
								id="name"
								label="Firma"
								variant="outlined"
								value={name}
								onChange={(e) => setName(e.target.value)}
								style={{ width: 300 }}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								id="address"
								label="Adresse"
								variant="outlined"
								value={address}
								onChange={(e) => setAddress(e.target.value)}
								style={{ width: 300 }}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								id="zip"
								label="Postnummer"
								variant="outlined"
								value={zip}
								onChange={(e) => setZip(e.target.value)}
								style={{ width: 300 }}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								id="city"
								label="By"
								variant="outlined"
								value={city}
								onChange={(e) => setCity(e.target.value)}
								style={{ width: 300 }}
							/>
						</Grid>
						{state.userIsAdmin ? (
							<Grid item xs={12}>
								<TextField
									id="discount"
									label="Rabat"
									variant="outlined"
									value={discount}
									onChange={(e) => setDiscount(e.target.value)}
									style={{ width: 300 }}
								/>
							</Grid>
						) : <></>}
						<Grid item xs={12}>
							<ButtonGroup variant="contained" size="large">
								<Button onClick={() => handleCancel()}>TILBAGE</Button>
								<Button onClick={() => handleSave()}>GEM</Button>
								{state.userIsAdmin ? (
									<>
										<Button onClick={() => handleDelete()}>SLET</Button>
										<Button onClick={() => handleAddUser()}>NY BRUGER</Button>
									</>
								) : <></>}
							</ButtonGroup>
						</Grid>
					</Grid>

					<AdministrationOrgUsers orgId={orgId} title="Brugere" />

					<ConfirmDialog visible={confirmOpen} title="Slet firma" text="Er du sikker på at du vil slette dette firma?" handleOk={() => handleDeleteDo()} handleCancel={() => setConfirmOpen(false)} />
				</>
			) : (
				<CircularProgress />
			)}
		</>
	)
}

export default AdministrationOrgEdit;