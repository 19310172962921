import { create } from 'apisauce';
import cookie from 'react-cookies';

const baseApi = create({
	baseURL: 'https://beregnerbackend.bygcom.com/rest',
	timout: 30000,
	headers: {
		'Accept': 'application/json',
		'Content-Type': 'application/json',
	}
});

export const login = async (postData) => {
	const response = await baseApi.post('/odeum/auth/basic', postData).then(rs => rs);
	return response;
}

export const getWalls = async () => {
	baseApi.setHeader('ODEUMAuthToken', cookie.load('token'));

	const data = await baseApi.get('/bygcom/products/wall').then(rs => rs.data);
	return data;
}

export const getDecks = async () => {
	baseApi.setHeader('ODEUMAuthToken', cookie.load('token'));

	const data = await baseApi.get('/bygcom/products/deck').then(rs => rs.data);
	return data;
}

export const setActiveOrder = async (orderId) => {
	baseApi.setHeader('ODEUMAuthToken', cookie.load('token'));

	const response = await baseApi.post('/bygcom/order/active/' + orderId).then(rs => rs);
	return response;
}

export const getActiveOrder = async () => {
	baseApi.setHeader('ODEUMAuthToken', cookie.load('token'));

	const response = await baseApi.get('/bygcom/order/active').then(rs => rs);
	return response;
}

export const getOrder = async (orderId) => {
	baseApi.setHeader('ODEUMAuthToken', cookie.load('token'));

	const response = await baseApi.get('/bygcom/order/' + orderId).then(rs => rs);
	return response;
}

export const addOrder = async () => {
	baseApi.setHeader('ODEUMAuthToken', cookie.load('token'));

	const response = await baseApi.post('/bygcom/order').then(rs => rs);
	return response;
}

export const updateOrder = async (orderId, postData) => {
	baseApi.setHeader('ODEUMAuthToken', cookie.load('token'));

	const response = await baseApi.put('/bygcom/order/' + orderId, postData).then(rs => rs);
	return response;
}

export const deleteOrder = async (orderId) => {
	baseApi.setHeader('ODEUMAuthToken', cookie.load('token'));

	const response = await baseApi.delete('/bygcom/order/' + orderId).then(rs => rs);
	return response;
}

export const getOrderPdf = async (orderId) => {
	baseApi.setHeader('ODEUMAuthToken', cookie.load('token'));

	const response = await baseApi.get('/bygcom/order/' + orderId + '/pdf').then(rs => rs);
	return response;
}

export const addOrderPanel = async (orderId, postData) => {
	baseApi.setHeader('ODEUMAuthToken', cookie.load('token'));

	const response = await baseApi.post('/bygcom/order/' + orderId + '/panel', postData).then(rs => rs);
	return response;
}

export const updateOrderItem = async (orderId, itemId, postData) => {
	baseApi.setHeader('ODEUMAuthToken', cookie.load('token'));

	const response = await baseApi.put('/bygcom/order/' + orderId + '/item/' + itemId, postData).then(rs => rs);
	return response;
}

export const deleteOrderItem = async (orderId, itemId) => {
	baseApi.setHeader('ODEUMAuthToken', cookie.load('token'));

	const response = await baseApi.delete('/bygcom/order/' + orderId + '/item/' + itemId).then(rs => rs);
	return response;
}

export const getOrderItemPdf = async (orderId, itemId) => {
	baseApi.setHeader('ODEUMAuthToken', cookie.load('token'));

	const response = await baseApi.get('/bygcom/order/' + orderId + '/item/' + itemId + '/pdf').then(rs => rs);
	return response;
}

export const getOrderItemImage = async (orderId, itemId) => {
	baseApi.setHeader('ODEUMAuthToken', cookie.load('token'));

	const response = await baseApi.get('/bygcom/order/' + orderId + '/item/' + itemId + '/image').then(rs => rs);
	return response;
}

export const getUserCalculations = async () => {
	baseApi.setHeader('ODEUMAuthToken', cookie.load('token'));

	const response = await baseApi.get('/bygcom/order/list?states=1,2,10').then(rs => rs);
	return response;
}

export const getOrganisations = async () => {
	baseApi.setHeader('ODEUMAuthToken', cookie.load('token'));

	const response = await baseApi.get('/core/orgs').then(rs => rs);
	return response;
}

export const getOrganisation = async (orgId) => {
	baseApi.setHeader('ODEUMAuthToken', cookie.load('token'));

	const response = await baseApi.get('/core/org/' + orgId).then(rs => rs);
	return response;
}

export const addOrganisation = async (postData) => {
	baseApi.setHeader('ODEUMAuthToken', cookie.load('token'));

	const response = await baseApi.post('/core/org', postData).then(rs => rs);
	return response;
}

export const updateOrganisation = async (orgId, postData) => {
	baseApi.setHeader('ODEUMAuthToken', cookie.load('token'));

	const response = await baseApi.put('/core/org/' + orgId, postData).then(rs => rs);
	return response;
}

export const deleteOrganisation = async (orgId) => {
	baseApi.setHeader('ODEUMAuthToken', cookie.load('token'));

	const response = await baseApi.delete('/core/org/' + orgId).then(rs => rs);
	return response;
}

export const getOrganisationUsers = async (orgId) => {
	baseApi.setHeader('ODEUMAuthToken', cookie.load('token'));

	const response = await baseApi.get('/core/org/users/' + orgId).then(rs => rs);
	return response;
}

export const getUsers = async () => {
	baseApi.setHeader('ODEUMAuthToken', cookie.load('token'));

	const data = baseApi.get('/core/users').then((result) => result);
	return data;
}

export const getUser = async (userId) => {
	baseApi.setHeader('ODEUMAuthToken', cookie.load('token'));

	const data = baseApi.get('/core/user/' + userId).then((result) => result);
	return data;
}

export const addUser = async (postData) => {
	const response = await baseApi.post('/bygcom/user', postData).then(rs => rs);
	return response;
}

export const updateUser = async (userId, postData) => {
	baseApi.setHeader('ODEUMAuthToken', cookie.load('token'));

	const response = await baseApi.put('/core/user/' + userId, postData).then(rs => rs);
	return response;
}

export const deleteUser = async (userId) => {
	baseApi.setHeader('ODEUMAuthToken', cookie.load('token'));

	const response = await baseApi.delete('/core/user/' + userId).then(rs => rs);
	return response;
}

export const confirmUser = async (postData) => {
	baseApi.setHeader('ODEUMAuthToken', cookie.load('token'));

	const response = await baseApi.post('/core/user/confirm', postData).then(rs => rs);
	return response;
}

export const sendOrderToCustomer = async (orderId) => {
	baseApi.setHeader('ODEUMAuthToken', cookie.load('token'));

	const response = await baseApi.post('/bygcom/order/' + orderId + '/sendtocustomer').then(rs => rs);
	return response;
}

export const sendOrderToBygcom = async (orderId) => {
	baseApi.setHeader('ODEUMAuthToken', cookie.load('token'));

	const response = await baseApi.post('/bygcom/order/' + orderId + '/sendtobygcom').then(rs => rs);
	return response;
}

export const forgotPassword = async (email) => {
	const response = await baseApi.post('/core/user/forgotpassword', { email }).then(rs => rs);
	return response;
}

export const setForgottenPassword = async (postData) => {
	const response = await baseApi.post('/core/user/forgotpassword/set', postData).then(rs => rs);
	return response;
}

export const getCompanyByCvr = async (cvr) => {
	const response = await baseApi.get('/core/org/bycvr/' + cvr).then(rs => rs);
	return response;
}
