import { useState } from 'react';
import { Typography, Grid, TextField, Button } from "@mui/material";
import { useParams } from 'react-router-dom';

import { forgotPassword, setForgottenPassword } from "../lib/api";

const ForgotPassword = () => {
	const { token } = useParams();

	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [passwordConfirm, setPasswordConfirm] = useState('');
	const [error, setError] = useState('');
	const [success, setSuccess] = useState('');

	const handleSend = async () => {
		setError('');
		setSuccess('');

		if (username === '') {
			setError('Du skal udfylde et brugernavn');
		} else {
			const result = await forgotPassword(username);

			if (!result.ok) {
				setError('Der opstod en fejl, prøv venligst igen eller kontakt Bygcom');
			} else {
				setSuccess('Vi har nu sendt en e-mail til dig med et link til at nulstille dit kodeord');
				setUsername('');
			}
		}
	}

	const handleSetNewPassword = async () => {
		setError('');
		setSuccess('');

		if (password === '' || passwordConfirm === '') {
			setError('Du skal udfylde dit nye kodeord og bekræfte det');
		} else if (password !== passwordConfirm) {
			setError('De to kodeord er ikke ens');
		} else {
			const postData = {
				passwordToken: token,
				newPassword: password
			}

			const result = await setForgottenPassword(postData);
			console.log(result.ok);

			if (!result.ok) {
				setError('Der opstod en fejl, prøv venligst igen eller kontakt Bygcom');
			} else {
				setSuccess('Dit kodeord er nu nulstillet, du kan nu logge ind med dit nye kodeord');
			}
		}
	}

	return (
		<>
			<Typography variant="h1">Glemt kodeord</Typography>

			{token === undefined ? (
				<>
					{success === '' ? (
						<>
							<Typography variant="body1">Har du glemt dit kodeord kan du nulstille det ved at udfylde nedenstående formular.</Typography>

							{error !== '' ? <Typography variant="body1" color="error" style={{ marginTop: 20 }}>{error}</Typography> : <></>}

							<Grid container spacing={2} style={{ marginTop: 20 }}>
								<Grid item xs={12}>
									<TextField
										id="username"
										name="username"
										label="E-mail"
										onChange={(e) => setUsername(e.target.value)}
										onKeyDown={(ev) => {
											if (ev.key === 'Enter') {
												ev.preventDefault();
												if (username !== '') {
													handleSend();
												}
											}
										}}
										style={{ width: 300 }}
									/>
								</Grid>
								<Grid item xs={12}>
									<Grid item xs={12}>
										<Button variant="contained" color="primary" onClick={() => handleSend()}>NULSTIL KODEORD</Button>
									</Grid>
								</Grid>
							</Grid>
						</>
					) : <Typography variant="body1" style={{ marginTop: 20 }}>{success}</Typography>}
				</>
			) : (
				<>
					{success === '' ? (
						<>
							<Typography variant="body1">Indtast dit nye kodeord nedenfor</Typography>

							{error !== '' ? <Typography variant="body1" color="error" style={{ marginTop: 20 }}>{error}</Typography> : <></>}

							<Grid container spacing={2} style={{ marginTop: 20 }}>
								<Grid item xs={12}>
									<TextField
										id="password"
										name="password"
										type="password"
										label="Kodeord"
										onChange={(e) => setPassword(e.target.value)}
										onKeyDown={(ev) => {
											if (ev.key === 'Enter') {
												ev.preventDefault();
												if (username !== '' && passwordConfirm !== '') {
													handleSetNewPassword();
												}
											}
										}}
										style={{ width: 300 }}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										id="passwordConfirm"
										name="passwordConfirm"
										type="password"
										label="Bekræft kodeord"
										onChange={(e) => setPasswordConfirm(e.target.value)}
										onKeyDown={(ev) => {
											if (ev.key === 'Enter') {
												ev.preventDefault();
												if (username !== '' && passwordConfirm !== '') {
													handleSetNewPassword();
												}
											}
										}}
										style={{ width: 300 }}
									/>
								</Grid>
								<Grid item xs={12}>
									<Grid item xs={12}>
										<Button variant="contained" color="primary" onClick={() => handleSetNewPassword()}>BEKRÆFT</Button>
									</Grid>
								</Grid>
							</Grid>
						</>
					) : (
						<Typography variant="body1" style={{ marginTop: 20 }}>{success}</Typography>
					)}
				</>
			)}
		</>
	);
};

export default ForgotPassword;