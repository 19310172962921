import React, { useEffect, useState } from "react";
import { Typography, Grid, Box, List, ListItem, ListItemText, ListItemButton, CircularProgress, Button, Divider } from "@mui/material";
import { useNavigate } from 'react-router-dom';

import useGlobalState from "../hooks/useGlobalState";
import { getWalls, getDecks, addOrder, addOrderPanel, getActiveOrder } from "../lib/api";
import StructureTypeBox from "../components/StructureTypeBox";

const Calculation = () => {
	const navigate = useNavigate();
	const [state, dispatch] = useGlobalState();

	const [loading1, setLoading1] = useState(true);
	const [loading2, setLoading2] = useState(true);
	const [availableStructures, setAvailableStructures] = useState(null);
	const [orderState, setOrderState] = useState(0);

	useEffect(() => {
		async function fetchData() {
			if (state.orderId !== null) {
				console.log('Has active order');
				const orderResult = await getActiveOrder();

				if (!orderResult.ok) {
					alert('Der opstod en fejl under hentning af ordre');

					navigate('/mycalculations');
				} else {
					console.log('Setting active order');
					setOrderState(orderResult.data.state);
					dispatch({ orderId: orderResult.data.id });
					setLoading1(false);
				}
			} else {
				setLoading1(false);
			}
		}

		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		async function fetchData() {
			if (!state.selectedStructureType) {
				setLoading2(false);
			} else {
				if (state.selectedStructureType.type === 'wall') {
					const data = await getWalls();
					if (data) {
						let availableStructuresNew = [];
						data.forEach(d => {
							if (availableStructuresNew[d.name] === undefined) {
								availableStructuresNew[d.name] = [];
							}
							availableStructuresNew[d.name].push({ id: d.id, thickness: d.profileData.thickness.toString().replace('.', ','), images: d.images });
						});

						setAvailableStructures(availableStructuresNew);
						setLoading2(false);
					}
				} else if (state.selectedStructureType.type === 'deck') {
					const data = await getDecks();
					if (data) {
						let availableStructuresNew = [];
						data.forEach(d => {
							if (availableStructuresNew[d.name] === undefined) {
								availableStructuresNew[d.name] = [];
							}
							availableStructuresNew[d.name].push({ id: d.id, thickness: d.profileData.thickness.toString().replace('.', ','), images: d.images });
						});

						setAvailableStructures(availableStructuresNew);
						setLoading2(false);
					}
				}
			}
		}

		fetchData();
	}, [state.selectedStructureType]);

	const handleStructureTypeSelect = async (structureType) => {
		dispatch({ selectedStructureType: structureType, selectedStructure: null });
	}

	const handleStructureSelect = (structure) => {
		dispatch({ selectedStructure: structure });
	}

	const handleAddElement = async () => {
		//check if an order has been created if not create one
		let orderId = null;
		if (state.orderId === null) {
			const orderResult = await addOrder();

			if (orderResult.ok) {
				console.log('Order created');
				setOrderState(orderResult.data.state);
				dispatch({ orderId: orderResult.data.id });
				orderId = orderResult.data.id;
			}
		} else {
			orderId = state.orderId;
		}

		if (!orderId) {
			alert('Kunne ikke oprette en ordre');
		} else {
			const postData = {
				"productId": state.selectedStructure.id,
				"type": state.selectedStructureType.id
			};

			const panelResult = await addOrderPanel(orderId, postData);
			if (!panelResult.ok) {
				alert('Der opstod en fejl');
			} else {
				dispatch({ selectedStructure: null, selectedStructureType: null });

				navigate('/calculation/edititem/' + panelResult.data.id);
			}
		}
	}

	return (
		<>
			<Typography variant="h1">Ny beregning - tilføj element</Typography>

			<Box mb={5}>
				<Typography>Vælg hvilken struktur der skal beregnes.</Typography>
			</Box>

			{!loading1 && !loading2 ? (
				<>
					{orderState < 2 ? (
						<>
							<Grid container spacing={3}>
								{state.structureTypes && state.structureTypes.map((structureType) => (
									<StructureTypeBox key={structureType.id} structureType={structureType} handleSelect={handleStructureTypeSelect} />
								))}
							</Grid>

							<Box mt={5}>
								{state.selectedStructureType ? (
									<>
										{!loading2 && availableStructures ? (
											<>
												<Grid container>
													{Object.keys(availableStructures).map((structureName) => {
														return (
															<Grid item xs={12} key={structureName}>
																<Grid container>
																	<Grid item xs={12}>
																		<Typography variant="h3">{structureName}</Typography>
																	</Grid>
																	<Grid item xs={2}>
																		<Typography>Vælg tykkelse</Typography>
																		<List style={{ maxWidth: 200, padding: 0 }}>
																			{availableStructures[structureName].map((structure) => {
																				return (
																					<ListItem key={structure.id} style={{ padding: 0 }}>
																						<ListItemButton onClick={() => handleStructureSelect(structure)} style={{ backgroundColor: (state.selectedStructure && state.selectedStructure.id === structure.id) ? '#ddd' : '#fff' }}>
																							<ListItemText primary={structure.thickness + ' mm'} />
																						</ListItemButton>
																					</ListItem>
																				);
																			})}
																		</List>
																	</Grid>
																	<Grid item xs={8}>
																		<Box display="flex">
																			{availableStructures[structureName].map((structure) => {
																				return structure.images.map((image) => {
																					return <div key={structureName + image}><img src={"/assets/structurethickness/" + image + ".png"} alt={structureName} style={{ maxWidth: 400 }} /></div>;
																				});
																			})}
																		</Box>
																	</Grid>
																</Grid>
																<Grid item xs={12}>
																	<Divider style={{ marginTop: 15, marginBottom: 15 }} />
																</Grid>
															</Grid>
														);
													})}
												</Grid>
												<Button variant="contained" onClick={() => handleAddElement()}>Tilføj element</Button>
											</>
										) : (
											<CircularProgress />
										)}
									</>
								) : (
									<Typography>Der er endnu ikke valgt struktur.</Typography>
								)}
							</Box>
						</>
					) : (
						<Typography>Denne ordre er afsendt og kan derfor ikke ændres</Typography>
					)}
				</>
			) : (
				<CircularProgress />
			)
			}
		</>
	);
}

export default Calculation;