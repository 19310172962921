import { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import cookie from 'react-cookies';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

import './App.css';
import MainContainer from './routes/MainContainer';

function App() {
	useEffect(() => {
		if (cookie.load('token') === undefined) {
			cookie.save('token', uuidv4(), { path: '/', expires: moment().add('30', 'days').toDate() });
		}
	}, []);

	return (
		<>
			<BrowserRouter>
				<Routes>
					<Route path="*" element={<MainContainer />}></Route>
					{/* <Route path="/password/confirm/:lang/:token" element={<ConfirmUser />}></Route> */}
				</Routes>
			</BrowserRouter>
		</>
	);
}

export default App;
