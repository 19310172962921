import { createTheme } from '@mui/material/styles';

const theme = createTheme({
	palette: {
		primary: {
			main: '#0b2a55',
		},
	},
	typography: {
		h1: {
			fontSize: '2rem',
			marginTop: '1rem',
			marginBottom: '1rem',
		},
		h2: {
			fontSize: '1.6rem',
			marginTop: '1rem',
			marginBottom: '1rem',
		},
		h3: {
			fontSize: '1.2rem',
			fontWeight: 300,
			marginTop: '1rem',
			marginBottom: '1rem',
		},
		body1: {
			fontSize: '1rem',
		},
	},
});

export default theme;