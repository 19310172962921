import { CircularProgress, Grid, TextField, Typography, ButtonGroup, Button } from "@mui/material";
import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { addUser } from "../lib/api";

const AdministrationUserAdd = () => {
	const { orgId } = useParams();
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);
	const [firstname, setFirstname] = useState('');
	const [lastname, setLastname] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');

	const handleCancel = () => {
		navigate('/administration/org/' + orgId + '/edit');
	}

	const handleSave = async () => {
		setLoading(true);

		const newUser = {};
		newUser.userName = email;
		newUser.firstName = firstname;
		newUser.lastName = lastname;
		newUser.email = email;
		newUser.phone = phone;
		newUser.org = { id: orgId };
		newUser.groups = [140890100000021];

		const result = await addUser(newUser);

		if (result.status === 400) {
			setLoading(false);

			alert('Brugeren eksisterer allerede');
		} else {
			if (!result.ok) {
				setLoading(false);

				alert('Der opstod en fejl');
			} else {
				setLoading(false);

				navigate('/administration/org/' + orgId + '/edit');
			}
		}
	}

	return (
		<>
			{!loading ? (
				<>
					<Typography variant="h1">Ny bruger</Typography>

					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TextField
								id="firstname"
								label="Fornavn"
								variant="outlined"
								value={firstname}
								onChange={(e) => setFirstname(e.target.value)}
								style={{ width: 300 }}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								id="lastname"
								label="Efternavn"
								variant="outlined"
								value={lastname}
								onChange={(e) => setLastname(e.target.value)}
								style={{ width: 300 }}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								id="email"
								label="E-mail"
								variant="outlined"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								style={{ width: 300 }}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								id="phone"
								label="Telefonnummer"
								variant="outlined"
								value={phone}
								onChange={(e) => setPhone(e.target.value)}
								style={{ width: 300 }}
							/>
						</Grid>
						<Grid item xs={12}>
							<ButtonGroup variant="contained" size="large">
								<Button onClick={() => handleCancel()}>ANNULER</Button>
								<Button onClick={() => handleSave()}>GEM</Button>
							</ButtonGroup>
						</Grid>
					</Grid>
				</>
			) : (
				<CircularProgress />
			)}
		</>
	)
}

export default AdministrationUserAdd;