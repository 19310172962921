import { useEffect, useState } from "react";
import { CircularProgress, Typography, IconButton, Tooltip } from "@mui/material"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import DoneIcon from '@mui/icons-material/Done';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Paper from '@mui/material/Paper';
import moment from "moment";
import 'moment/locale/da';
import { useNavigate } from "react-router-dom";

import useGlobalState from "../hooks/useGlobalState";
import { getUserCalculations, setActiveOrder } from '../lib/api';
import { getComparator, stableSort } from "../lib/functions";
import TableHeader from "../components/TableHeader";

const MyCalculations = () => {
	const navigate = useNavigate();
	const [state, dispatch] = useGlobalState();

	const [loading, setLoading] = useState(true);
	const [orders, setOrders] = useState([]);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(15);
	const [sortOrder, setSortOrder] = useState('desc');
	const [sortOrderBy, setSortOrderBy] = useState('created');

	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orders.length) : 0;

	const headerCells = [
		{
			id: 'id',
			numeric: true,
			label: 'ID',
		},
		{
			id: 'title',
			numeric: false,
			label: 'Titel',
		},
		{
			id: 'company',
			numeric: false,
			label: 'Firma',
		},
		{
			id: 'state',
			numeric: true,
			label: 'Status',
		},
		{
			id: 'price',
			numeric: false,
			label: 'Pris',
		},
		{
			id: 'created',
			numeric: false,
			label: 'Oprettet',
		},
		{
			id: 'actions',
			numeric: false,
			label: 'Handlinger',
		},
	];

	useEffect(() => {
		async function fetchData() {
			const result = await getUserCalculations();

			if (result.ok) {
				let newOrders = [];

				result.data.forEach((order) => {
					newOrders.push({
						id: order.id,
						title: order.title,
						company: order.customer.company,
						state: order.state,
						price: order.price,
						created: order.created,
					});
				});

				setOrders(newOrders);
			}

			setLoading(false);
		}

		fetchData();
	}, []);

	const handleRequestSort = (event, property) => {
		const isAsc = sortOrderBy === property && sortOrder === 'asc';
		setSortOrder(isAsc ? 'desc' : 'asc');
		setSortOrderBy(property);
	}

	const handleRowClick = async (order) => {
		if (order.state < 2) {
			dispatch({ orderId: order.id });

			const orderResult = await setActiveOrder(order.id);

			if (!orderResult.ok) {
				alert('Kunne ikke sætte den aktive ordre.')
			} else {
				navigate('/order');
			}
		} else {
			navigate('/order/' + order.id);
		}
	}

	const renderState = (state) => {
		switch (state) {
			default:
			case 1:
				return 'Kladde';
			case 2:
				return 'Afsendt';
			case 10:
				return 'Behandlet';
		}
	}

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	}

	const handleMarkAsCompleted = (e, orderId) => {
		e.stopPropagation();
		console.log('Mark as completed', orderId);
	}

	return (
		<>
			<Typography variant="h1">Mine beregninger</Typography>

			{!loading ? (
				<>
					{!orders.length ? (
						<Typography variant="body1">Du har endnu ikke foretaget nogle beregninger</Typography>
					) : (
						<>
							<TableContainer component={Paper}>
								<Table sx={{ minWidth: 650 }}>
									<TableHeader
										order={sortOrder}
										orderBy={sortOrderBy}
										onRequestSort={handleRequestSort}
										headerCells={headerCells}
									/>
									<TableBody>
										{stableSort(orders, getComparator(sortOrder, sortOrderBy))
											.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
											.map((order) => {
												return <TableRow
													hover
													key={order.id}
													onClick={() => handleRowClick(order)}
													style={{ cursor: 'pointer' }}
												>
													<TableCell>{order.id}</TableCell>
													<TableCell>{order.title}</TableCell>
													<TableCell>{order.company}</TableCell>
													<TableCell>{renderState(order.state)}</TableCell>
													<TableCell align="right">{new Intl.NumberFormat('da-DK').format(Math.round(order.price * 100) / 100)} kr.</TableCell>
													<TableCell align="right">{moment(order.created).format('lll')}</TableCell>
													<TableCell align="center">
														<Tooltip title="Se ordre">
															<IconButton>
																<VisibilityIcon />
															</IconButton>
														</Tooltip>

														{state.userIsAdmin ? (
															<Tooltip title="Marker som behandlet">
																<IconButton
																	disabled={order.state === 10 ? true : false}
																	onClick={(e) => handleMarkAsCompleted(e, order.id)}>
																	<DoneIcon />
																</IconButton>
															</Tooltip>
														) : <></>}
													</TableCell>
												</TableRow>
											})}
										{emptyRows > 0 && (
											<TableRow
												style={{
													height: 53 * emptyRows,
												}}
											>
												<TableCell colSpan={6} />
											</TableRow>
										)}
									</TableBody>
								</Table>
							</TableContainer>
							<TablePagination
								rowsPerPageOptions={[15, 25, 50, 100]}
								component="div"
								count={orders.length}
								rowsPerPage={rowsPerPage}
								page={page}
								onPageChange={handleChangePage}
								onRowsPerPageChange={handleChangeRowsPerPage}
							/>
						</>
					)}
				</>

			) : (
				<CircularProgress />
			)
			}
		</>
	)
}

export default MyCalculations;