import { useState } from 'react';
import { CircularProgress, Typography } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
// import DeleteIcon from '@mui/icons-material/Delete';
// import CheckIcon from '@mui/icons-material/Check';
import { useNavigate } from "react-router-dom";

import { getComparator, stableSort } from "../lib/functions";
import TableHeader from "../components/TableHeader";
import { useEffect } from 'react';
import { getOrganisationUsers } from '../lib/api';

const AdministrationOrgUsers = ({ orgId, title }) => {
	const navigate = useNavigate();

	const [loading, setLoading] = useState(true);
	const [users, setUsers] = useState([]);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(15);
	const [sortOrder, setSortOrder] = useState('asc');
	const [sortOrderBy, setSortOrderBy] = useState('name');

	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

	const headerCells = [
		{
			id: 'name',
			numeric: false,
			label: 'Navn',
		},
		{
			id: 'phone',
			numeric: false,
			label: 'Telefon',
		},
		{
			id: 'email',
			numeric: false,
			label: 'E-mail',
		},
		{
			id: 'validated',
			numeric: false,
			label: 'Godkendt',
		},
		// {
		// 	id: 'actions',
		// 	numeric: false,
		// 	label: 'Handlinger',
		// }
	];

	useEffect(() => {
		async function fetchData() {
			const resultUsers = await getOrganisationUsers(orgId);

			if (resultUsers.ok) {
				let newUsers = [];
				resultUsers.data.forEach(user => {
					newUsers.push({
						id: user.id,
						name: user.firstName + ' ' + user.lastName,
						phone: user.phone,
						email: user.email,
						suspended: user.suspended,
					});
				});

				setUsers(newUsers);

				setLoading(false);
			}
		}

		fetchData();
	}, [orgId]);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	}

	const handleRequestSort = (event, property) => {
		const isAsc = sortOrderBy === property && sortOrder === 'asc';
		setSortOrder(isAsc ? 'desc' : 'asc');
		setSortOrderBy(property);
	}

	const handleRowClick = (id) => {
		navigate('/administration/user/' + id + '/edit');
	}

	// const handleDelete = (id) => {

	// }

	return (
		<>
			<Typography variant="h2">{title}</Typography>

			{!loading ? (
				<>

					{!users || users.length === 0 ? (
						<Typography variant="h3">Ingen brugere tilføjet på dette firma</Typography>
					) : (
						<>
							<TableContainer component={Paper}>
								<Table sx={{ minWidth: 650 }}>
									<TableHeader
										order={sortOrder}
										orderBy={sortOrderBy}
										onRequestSort={handleRequestSort}
										headerCells={headerCells}
									/>
									<TableBody>
										{stableSort(users, getComparator(sortOrder, sortOrderBy))
											.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
											.map((user) => (
												<TableRow
													hover
													key={user.id}
													onClick={() => handleRowClick(user.id)}
													style={{ cursor: 'pointer' }}
												>
													<TableCell>{user.name}</TableCell>
													<TableCell>{user.phone}</TableCell>
													<TableCell>{user.email}</TableCell>
													<TableCell>{user.suspended ? 'Nej' : 'Ja'}</TableCell>
													{/* <TableCell>
														<IconButton onClick={() => handleValidateUser(user.id)}>
															<CheckIcon />
														</IconButton>
														<IconButton	onClick={() => handleDelete(user.id)}>
															<DeleteIcon />
														</IconButton>

													</TableCell> */}
												</TableRow>
											))}
										{emptyRows > 0 && (
											<TableRow
												style={{
													height: 53 * emptyRows,
												}}
											>
												<TableCell colSpan={6} />
											</TableRow>
										)}
									</TableBody>
								</Table>
							</TableContainer>
							<TablePagination
								rowsPerPageOptions={[15, 25, 50, 100]}
								component="div"
								count={users.length}
								rowsPerPage={rowsPerPage}
								page={page}
								onPageChange={handleChangePage}
								onRowsPerPageChange={handleChangeRowsPerPage}
							/>
						</>
					)}
				</>
			) : (
				<CircularProgress />
			)}
		</>
	)

}

export default AdministrationOrgUsers