import { useState, useEffect } from 'react';
import { Button, Grid, TextField, Typography } from '@mui/material';

const ItemCutout = ({ cutout, index, handleUpdate, handleDelete }) => {
	useEffect(() => {
		setNote(cutout.note);
		setA(cutout.size.y);
		setB(cutout.size.x);
		setC(cutout.origin.y);
		setD(cutout.origin.x);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [note, setNote] = useState('');
	const [a, setA] = useState('');
	const [b, setB] = useState('');
	const [c, setC] = useState('');
	const [d, setD] = useState('');

	const onChange = (e) => {
		let newCutout = { ...cutout };

		if (e.target.id === 'note') {
			setNote(e.target.value);
			newCutout.note = e.target.value;
		} else if (e.target.id === 'a') {
			setA(e.target.value);
			newCutout.size.y = e.target.value;
		} else if (e.target.id === 'b') {
			setB(e.target.value);
			newCutout.size.x = e.target.value;
		} else if (e.target.id === 'c') {
			setC(e.target.value);
			newCutout.origin.y = e.target.value;
		} else if (e.target.id === 'd') {
			setD(e.target.value);
			newCutout.origin.x = e.target.value;
		}

		handleUpdate(newCutout, index);
	}

	return (
		<>
			<Grid container style={{ marginTop: 10 }}>
				<Grid item xs={12}>
					<TextField
						id="note"
						label="Note"
						variant="outlined"
						value={note}
						onChange={(e) => onChange(e)}
						style={{ width: 300 }}
					/>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="h3">Størrelse</Typography>

					<TextField
						id="b"
						label="B: Bredde i mm"
						variant="outlined"
						value={b}
						onChange={(e) => onChange(e)}
						style={{ width: 300, marginRight: 10 }}
					/>
					<TextField
						id="a"
						label="A: Højde i mm"
						variant="outlined"
						value={a}
						onChange={(e) => onChange(e)}
						style={{ width: 300 }}
					/>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="h3">Placering</Typography>

					<TextField
						id="d"
						label="D: Bredde til udsparring i mm"
						variant="outlined"
						value={d}
						onChange={(e) => onChange(e)}
						style={{ width: 300, marginRight: 10 }}
					/>
					<TextField
						id="c"
						label="C: Højde til udsparring i mm"
						variant="outlined"
						value={c}
						onChange={(e) => onChange(e)}
						style={{ width: 300 }}
					/>
				</Grid>
				<Grid item xs={12}>
					<Button variant="contained" color="primary" style={{ marginTop: 10 }} onClick={handleDelete}>Slet udsparring</Button>
				</Grid>
			</Grid>
		</>
	)
}

export default ItemCutout;