import { useState } from "react";
import { Grid, TextField, Typography, Button } from "@mui/material"
import { useParams, useNavigate } from "react-router-dom";

import { confirmUser } from "../lib/api";

const ConfirmUser = () => {
	const { token } = useParams();
	const navigate = useNavigate();

	const [error, setError] = useState('');
	const [success, setSuccess] = useState(false);
	const [password, setPassword] = useState('');
	const [passwordConfirm, setPasswordConfirm] = useState('');

	const handleConfirm = async () => {
		setError('');

		if (!password.length || !passwordConfirm.length) {
			setError('Du skal indtaste en adgangskode og bekræfte den.');
		} else if (password !== passwordConfirm) {
			setError('De to indtastede adgangskoder er ikke ens.');
		} else {
			const postData = {
				newPassword: password,
				passwordToken: token
			}

			const confirmResult = await confirmUser(postData);

			if (!confirmResult.ok) {
				setError('Der opstod en fejl ved bekræftelse af brugeren. Kontakt Bygcom for at få hjælp.');
			} else {
				setSuccess(true);
			}
		}
	}

	return (
		<>
			{!success ? (
				<>
					<Typography variant="h1">Bekræft din bruger</Typography>

					<Typography variant="body1" style={{ marginBottom: 20 }}>Du er nu blevet oprettet som bruger i vores system. For at kunne logge ind skal bekræfte din bruger ved at indtaste en adgangskode og tykke brkræft.</Typography>

					{error && <Typography variant="body1" style={{ color: 'red', marginBottom: 20 }}>{error}</Typography>}

					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TextField
								id="password"
								label="Adgangskode"
								type="password"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								variant="outlined"
								style={{ width: 300 }}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								id="passwordconfirm"
								label="Bekræft adgangskode"
								type="password"
								value={passwordConfirm}
								onChange={(e) => setPasswordConfirm(e.target.value)}
								variant="outlined"
								style={{ width: 300 }}
							/>
						</Grid>
						<Grid item xs={12}>
							<Button variant="contained" onClick={() => handleConfirm()}>Bekræft</Button>
						</Grid>
					</Grid>
				</>
			) : (
				<>
					<Typography variant="h1">Din bruger er hermed bekræftet og du kan logge ind</Typography>

					<Button variant="contained" onClick={() => navigate('/login')}>Gå til log ind</Button>
				</>
			)}
		</>
	)
}

export default ConfirmUser;