import React, { useEffect, useReducer } from 'react';

let reducer = (info, newInfo) => {
	if (newInfo === null) {
		localStorage.removeItem("globalState");
		return defaultGlobalState;
	}

	return { ...info, ...newInfo };
};

export const defaultGlobalState = {
	user: null,
	userIsAdmin: false,
	orderId: null,
	selectedStructureType: null,
	selectedStructure: null,
	structureTypes: [
		{
			id: 1,
			name: 'Væg rektangulær',
			type: 'wall',
		},
		{
			id: 2,
			name: 'Væg med kip',
			type: 'wall',
		},
		{
			id: 3,
			name: 'Væg skrå',
			type: 'wall',
		},
		{
			id: 4,
			name: 'Væg med forskudt kip',
			type: 'wall',
		},
		{
			id: 100,
			name: 'Gulv',
			type: 'deck',
		},

	]
};

export const GlobalStateContext = React.createContext(defaultGlobalState);
export const DispatchStateContext = React.createContext(undefined);

const localState = JSON.parse(localStorage.getItem("globalState"));

const GlobalStateProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, localState || defaultGlobalState);

	useEffect(() => {
		localStorage.setItem("globalState", JSON.stringify({ ...state }));
	}, [state]);

	return (
		<GlobalStateContext.Provider value={state}>
			<DispatchStateContext.Provider value={dispatch}>{children}</DispatchStateContext.Provider>
		</GlobalStateContext.Provider>
	);
};

export default GlobalStateProvider;