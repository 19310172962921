import { useState, useEffect } from "react";
import { Typography, ButtonGroup, Button, Grid, TextField, Box, CircularProgress, List, ListItem, ListItemButton, Divider, FormControl, Select, MenuItem, Alert, Snackbar } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import 'moment/locale/da';
import cookie from 'react-cookies';

import useGlobalState from "../hooks/useGlobalState";
import { getActiveOrder, getOrder, updateOrder, deleteOrder, getUsers, sendOrderToCustomer, sendOrderToBygcom, getOrganisation, getOrderPdf } from "../lib/api";
import ConfirmDialog from "../components/ConfirmDialog";

const Order = () => {
	const { orderId } = useParams();
	const navigate = useNavigate();
	const [state, dispatch] = useGlobalState();

	const [loading, setLoading] = useState(true);
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [confirmDialogVisible, setConfirmDialogVisible] = useState(false);
	const [confirmSendToCustomerDialogVisible, setConfirmSendToCustomerDialogVisible] = useState(false);
	const [confirmSendToBygcomDialogVisible, setConfirmSendToBygcomDialogVisible] = useState(false);
	const [alertSentToCustomer, setAlertSentToCustomer] = useState(false);
	const [alertSentToBygcom, setAlertSentToBygcom] = useState(false);
	const [order, setOrder] = useState(null);
	const [orderState, setOrderState] = useState(0);
	const [title, setTitle] = useState('');
	const [note, setNote] = useState('');
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [company, setCompany] = useState('');
	const [cvr, setCvr] = useState('');
	const [address, setAddress] = useState('');
	const [postCode, setPostCode] = useState('');
	const [city, setCity] = useState('');
	const [salesperson, setSalesperson] = useState('');
	const [users, setUsers] = useState([]);
	const [discount, setDiscount] = useState(0);
	const [supplier, setSupplier] = useState(null);

	useEffect(() => {
		async function fetchData() {
			let orderResult = null;

			if (orderId === undefined) {
				orderResult = await getActiveOrder();
			} else {
				orderResult = await getOrder(orderId);
			}

			if (orderResult.ok) {
				setOrder(orderResult.data);
				setOrderState(orderResult.data.state);
				setTitle(orderResult.data.title);
				setNote(orderResult.data.note);
				setName(orderResult.data.customer.name);
				setEmail(orderResult.data.customer.email);
				setPhone(orderResult.data.customer.phone);
				setCompany(orderResult.data.customer.company);
				setCvr(orderResult.data.customer.cvr);
				setAddress(orderResult.data.customer.address);
				setPostCode(orderResult.data.customer.postCode);
				setCity(orderResult.data.customer.city);

				if (cookie.load('SESSION') !== undefined) {
					if (state.user.org.id !== 140890100001721) {
						const usersResult = await getUsers();

						if (usersResult.ok) {
							setUsers(usersResult.data);

							const salesPersonFound = usersResult.data.find(u => u.id === orderResult.data.userId);
							if (salesPersonFound !== undefined) {
								setSalesperson(salesPersonFound);
							}
						}

						const orgResult = await getOrganisation(state.user.org.id);

						if (orgResult.ok) {
							if (orderResult.data.state < 2) { // not sent
								setDiscount(orgResult.data.aux?.discount?.replace('.', ','));
							} else if (orderResult.data.discount) {
								setDiscount(orderResult.data.discount);
							}
						}
					} else {
						const supplierRestlt = await getOrganisation(state.user.aux.supplier);

						if (supplierRestlt.ok) {
							setSupplier(supplierRestlt.data);
						}
					}

					setIsLoggedIn(true);
				}

				setLoading(false);
			} else {
				navigate('/calculation');
			}
		}

		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSave = async () => {
		let newOrder = { ...order };
		newOrder.title = title;
		newOrder.note = note;
		newOrder.customer.name = name;
		newOrder.customer.email = email;
		newOrder.customer.phone = phone;
		newOrder.customer.company = company;
		newOrder.customer.cvr = cvr;
		newOrder.customer.address = address;
		newOrder.customer.postCode = postCode;
		newOrder.customer.city = city;
		newOrder.userId = salesperson.id;

		setLoading(true);

		const updateResult = await updateOrder(order.id, newOrder);

		if (!updateResult.ok) {
			alert('Der opstod en fejl under opdateringen af ordren. Prøv igen senere.');
		} else {
			const activeOrderResult = await getActiveOrder();

			if (activeOrderResult.ok) {
				setOrder(activeOrderResult.data);

				setLoading(false);
			}
		}
	};

	const handleDelete = () => {
		setConfirmDialogVisible(true);
	}

	const handleDeleteDo = async () => {
		const deleteResult = await deleteOrder(order.id);

		if (!deleteResult.ok) {
			alert('Der opstod en fejl under sletningen af ordren. Prøv igen senere.');
		} else {
			dispatch({ orderId: null });
			setConfirmDialogVisible(false);
			navigate('/');
		}
	}

	const handleDeleteCancel = () => {
		setConfirmDialogVisible(false);
	}

	const handleGetPDF = async () => {
		const pdfResult = await getOrderPdf(order.id);

		if (!pdfResult.ok) {
			alert('Der opstod en fejl under hentningen af PDF');
		} else {
			const linkSource = `data:application/pdf;base64,${pdfResult.data}`;
			const downloadLink = document.createElement('a');
			const fileName = order.id + '.pdf';

			downloadLink.href = linkSource;
			downloadLink.download = fileName;
			downloadLink.click();
		}
	}

	const handleSendToCustomer = () => {
		if (!name.length || !email.length) {
			alert('Du skal udfylde navn og email for at kunne sende ordren til kunden.');
		} else {
			setConfirmSendToCustomerDialogVisible(true);
		}
	}

	const handleSendToCustomerDo = async () => {
		setConfirmSendToCustomerDialogVisible(false);

		setLoading(true);

		//save customer info before sending if not saved
		await handleSave();

		const result = await sendOrderToCustomer(order.id);

		if (!result.ok) {
			alert('Der opstod en fejl under afsendelsen af ordren til kunden.');
		} else {
			setAlertSentToCustomer(true);
		}

		setLoading(false);
	}

	const handleSendToBygcom = () => {
		if (!name.length || !email.length || !phone.length || !company.length || !cvr.length || !address.length || !postCode.length || !city.length) {
			alert('Du skal udfylde alle felter for at kunne afsende orderen.');
		} else {
			setConfirmSendToBygcomDialogVisible(true);
		}
	}

	const handleSendToBygcomDo = async () => {
		setConfirmSendToBygcomDialogVisible(false);

		setLoading(true);

		//save customer info before sending if not saved
		await handleSave();

		const result = await sendOrderToBygcom(order.id);

		if (!result.ok) {
			alert('Der opstod en fejl under afsendelsen af ordren.');
		} else {
			setAlertSentToBygcom(true);

			setOrderState(2);
		}

		setLoading(false);
	}

	const handleEditItem = (item) => {
		if (orderState < 2) {
			navigate('/calculation/edititem/' + item.id);
		}
	}

	const handleSalespersonChange = (event) => {
		const userFound = users.find(user => user.id === event.target.value);

		if (userFound !== undefined) {
			setSalesperson(userFound);
		}
	}

	const handleNewCalculation = () => {
		dispatch({ orderId: null });

		navigate('/calculation');
	}

	return (
		<>
			<Typography variant="h1">Ordre</Typography>

			{!loading ? (
				<>
					<Grid container spacing={5}>
						<Grid item xs={12} md={7}>
							{orderState < 2 ? <Button variant="contained" color="primary" onClick={() => navigate('/calculation')} style={{ marginTop: 20 }}>Tilføj element</Button> : <></>}

							<Grid container spacing={2} style={{ marginTop: 10 }}>
								<List style={{ width: '100%' }}>
									{order.items.map((item) => (
										<ListItem key={item.id} style={{ paddingRight: 0, marginTop: 10 }}>
											<ListItemButton style={{ padding: 0 }} onClick={() => handleEditItem(item)}>
												<Grid item xs={10}>
													<Box style={{ height: 40, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
														<img src={'/assets/structuretypes/' + item.panels[0].type + '.svg'} alt="" style={{ width: 50, marginRight: 10 }} />
														<Typography>{item.description}</Typography>
													</Box>
												</Grid>
												<Grid item xs={2} style={{ textAlign: 'right' }}>
													<Box style={{ height: 40, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
														<Typography>{new Intl.NumberFormat('da-DK').format(Math.round(item.price * 100) / 100)} kr.</Typography>
													</Box>
												</Grid>
											</ListItemButton>
										</ListItem>
									))}
								</List>
							</Grid>

							<Divider style={{ marginTop: 20, marginBottom: 20 }} />

							<Grid container spacing={1}>
								{discount ? (
									<>
										<Grid item xs={8}>
											<Typography>Rabat</Typography>
										</Grid>
										<Grid item xs={4} style={{ textAlign: 'right' }}>
											<Typography>{new Intl.NumberFormat('da-DK').format(Math.round(discount * 100) / 100)} %</Typography>
										</Grid>
									</>
								) : <></>}

								<Grid item xs={8}>
									<Typography>Total pris</Typography>
								</Grid>
								<Grid item xs={4} style={{ textAlign: 'right' }}>
									<Typography>{new Intl.NumberFormat('da-DK').format(Math.round(order.price * 100) / 100)} kr.</Typography>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} md={5}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Box
										display="flex"
										justifyContent="flex-end"
										alignItems="flex-end"
										style={{ marginTop: 20 }}
									>
										<Button variant="contained" color="primary" onClick={() => handleNewCalculation()}>Ny beregning</Button>
									</Box>
								</Grid>
								<Grid item xs={12}>
									<TextField
										id="title"
										label="Titel"
										variant="outlined"
										value={title}
										onChange={(e) => setTitle(e.target.value)}
										style={{ width: '100%' }}
										disabled={orderState >= 2}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										id="note"
										label="Note"
										variant="outlined"
										value={note}
										onChange={(e) => setNote(e.target.value)}
										style={{ width: '100%' }}
										InputProps={{ sx: { height: 80 } }}
										multiline
										disabled={orderState >= 2}
									/>
								</Grid>
								<Grid item xs={12}>
									<Typography>Oprettet: {moment(order.created).format('lll')}</Typography>
									{orderState >= 2 ? (
										<>
											<Typography style={{ fontWeight: 'bold', marginTop: 10 }}>Denne ordre er {orderState === 10 ? 'behandlet' : 'afsendt'} og kan derfor ikke ændres</Typography>
											<ButtonGroup variant="contained" size="medium" style={{ marginTop: 10 }}>
												{orderState !== 10 ? <Button onClick={() => alert('not implemented')}>Marker som behandlet</Button> : <></>}
												<Button onClick={() => handleGetPDF()}>Hent PDF</Button>
											</ButtonGroup>
										</>
									) : <></>}
								</Grid>
								<Grid item xs={12}>
									<Typography variant="h2">Kundeinformation</Typography>
								</Grid>
								<Grid item xs={12}>
									<TextField
										id="name"
										label="Navn"
										variant="outlined"
										value={name}
										onChange={(e) => setName(e.target.value)}
										style={{ width: '100%' }}
										disabled={orderState >= 2}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										id="email"
										label="E-mail"
										variant="outlined"
										value={email}
										onChange={(e) => setEmail(e.target.value)}
										style={{ width: '100%' }}
										disabled={orderState >= 2}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										id="phone"
										label="Telefonnummer"
										variant="outlined"
										value={phone}
										onChange={(e) => setPhone(e.target.value)}
										style={{ width: '100%' }}
										disabled={orderState >= 2}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										id="company"
										label="Firma"
										variant="outlined"
										value={company}
										onChange={(e) => setCompany(e.target.value)}
										style={{ width: '100%' }}
										disabled={orderState >= 2}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										id="cvr"
										label="CVR"
										variant="outlined"
										value={cvr}
										onChange={(e) => setCvr(e.target.value)}
										style={{ width: '100%' }}
										disabled={orderState >= 2}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										id="address"
										label="Adresse"
										variant="outlined"
										value={address}
										onChange={(e) => setAddress(e.target.value)}
										style={{ width: '100%' }}
										disabled={orderState >= 2}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										id="postCode"
										label="Postnummer"
										variant="outlined"
										value={postCode}
										onChange={(e) => setPostCode(e.target.value)}
										style={{ width: '100%' }}
										disabled={orderState >= 2}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										id="city"
										label="By"
										variant="outlined"
										value={city}
										onChange={(e) => setCity(e.target.value)}
										style={{ width: '100%' }}
										disabled={orderState >= 2}
									/>
								</Grid>

								{isLoggedIn ? (
									<>
										<Grid item xs={12}>
											<Typography variant="h2">Sælger</Typography>
										</Grid>
										<Grid item xs={12}>
											{state.user.org.id !== 140890100001721 ? (
												<>
													{orderState < 2 ? (
														<FormControl fullWidth>
															<Select
																value={salesperson.id}
																onChange={handleSalespersonChange}
																style={{ marginBottom: 20 }}
															>
																{users.map((user) => (
																	<MenuItem value={user.id} key={user.id}>{user.firstName + ' ' + user.lastName}</MenuItem>
																))}
															</Select>
														</FormControl>
													) : (
														<>
															{salesperson ? <Typography>{salesperson.firstName + ' ' + salesperson.lastName}</Typography> : <Typography>Ingen valgt</Typography>}
														</>
													)}
												</>
											) : (
												<>
													{supplier ? <Typography>{supplier.name}</Typography> : <Typography>Ingen valgt</Typography>}
												</>
											)}
										</Grid>
									</>
								) : (
									<></>
								)}

								{orderState < 2 ? (
									<Grid item xs={12}>
										<Box
											sx={{
												display: 'flex',
												flexDirection: { xs: 'column', md: 'row' },
												alignItems: 'center',
												justifyContent: 'center',
											}}
										>
											<ButtonGroup variant="contained" size="large">
												<Button onClick={() => handleSave()}>Gem</Button>
												<Button onClick={() => handleDelete()}>Slet</Button>
												<Button onClick={() => handleGetPDF()}>Hent PDF</Button>
											</ButtonGroup>
										</Box>
										<Box
											sx={{
												display: 'flex',
												flexDirection: { xs: 'column', md: 'row' },
												alignItems: 'center',
												justifyContent: 'center',
												marginTop: 2,
											}}
										>
											<ButtonGroup variant="contained" size="large">
												{isLoggedIn && state.user.org.id !== 140890100001721 ? <Button onClick={() => handleSendToCustomer()}>Send til kunde</Button> : <></>}
												<Button onClick={() => handleSendToBygcom()}>Afsend ordre</Button>
											</ButtonGroup>
										</Box>
									</Grid>
								) : (
									<></>
								)}
							</Grid>
						</Grid>
					</Grid>

					<ConfirmDialog
						visible={confirmDialogVisible}
						title="Slet ordre"
						text="Er du sikker på at du vil slette denne ordre?"
						handleOk={() => handleDeleteDo()}
						handleCancel={() => handleDeleteCancel()}
					/>

					<ConfirmDialog
						visible={confirmSendToCustomerDialogVisible}
						title="Send ordre til kunde"
						text="Er du sikker på at du vil sende denne ordre til kunden?"
						handleOk={() => handleSendToCustomerDo()}
						handleCancel={() => setConfirmSendToCustomerDialogVisible(false)}
					/>

					<ConfirmDialog
						visible={confirmSendToBygcomDialogVisible}
						title="Send ordre til Bygcom"
						text="Er du sikker på at du vil sende denne ordre til Bygcom?"
						handleOk={() => handleSendToBygcomDo()}
						handleCancel={() => setConfirmSendToBygcomDialogVisible(false)}
					/>

					<Snackbar
						anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
						open={alertSentToCustomer}
						onClose={() => setAlertSentToCustomer(false)}
						autoHideDuration={4000}>
						<Alert variant="filled" severity="success">
							Ordren blev afsendt til kunden!
						</Alert>
					</Snackbar>

					<Snackbar
						anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
						open={alertSentToBygcom}
						onClose={() => setAlertSentToBygcom(false)}
						autoHideDuration={4000}>
						<Alert variant="filled" severity="success">
							Ordren blev afsendt!
						</Alert>
					</Snackbar>
				</>
			) : (
				<CircularProgress />
			)}
		</>
	)
}

export default Order;