import { Grid, Typography } from '@mui/material';

import useGlobalState from "../hooks/useGlobalState";

const StructureTypeBox = ({ structureType, handleSelect }) => {
	const [state] = useGlobalState();

	return (
		<Grid item xs={12} sm={6} md={4} onClick={() => handleSelect(structureType)} style={{ cursor: 'pointer', textAlign: 'center', border: (state.selectedStructureType && structureType.id === state.selectedStructureType.id) ? 'solid 1px #ddd' : 'none' }}>
			<img src={"/assets/structuretypes/" + structureType.id + ".png"} alt={structureType.name} />
			<Typography variant="h3">{structureType.name}</Typography>
		</Grid>
	)
}

export default StructureTypeBox;