import { Grid, Typography } from "@mui/material";
import nyberegning from '../assets/nyberegning.png';

const Frontpage = () => {
	return (
		<>
			<Typography variant="h1">Velkommen til Bygcom beregner</Typography>
			<Grid container>
				<Grid item xs={12} md={6}>
					<Typography>Her har du har mulighed for at skræddersy dine lette vægge i stålprofiler.</Typography>
					<Typography>Vores brugervenlige webberegner giver dig mulighed for at definere forskellige typer vægge, indsætte åbninger til døre, vinduer, installationer og meget mere. Du kan også definere dæk med udsparinger.</Typography>
					<Typography>Få øjeblikkelig information om pris, vægt samt tegninger, vælg om du ønsker at modtage elementerne som saml selv eller samlet.</Typography>
					<Typography>Alt dette gør det let for dig at planlægge og bestille dine elementer til dig projekt.</Typography>
					<Typography>Velkommen til en mere effektiv og praktisk måde at håndtere dine byggebehov på!</Typography>
					<Typography>&nbsp;</Typography>
				</Grid>
				<Grid item xs={12} md={6}>
					<img src={nyberegning} alt="Bygcom beregner" />
				</Grid>
			</Grid>
		</>
	);
}

export default Frontpage;