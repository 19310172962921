import { CircularProgress, Grid, TextField, Typography, ButtonGroup, Button } from "@mui/material";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { getUser, updateUser, deleteUser } from "../lib/api";
import ConfirmDialog from "../components/ConfirmDialog";

const AdministrationUserEdit = () => {
	const { userId } = useParams();
	const navigate = useNavigate();

	const [loading, setLoading] = useState(true);
	const [confirmOpen, setConfirmOpen] = useState(false);
	const [user, setUser] = useState({});
	const [firstname, setFirstname] = useState('');
	const [lastname, setLastname] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');

	useEffect(() => {
		async function fetchData() {
			const result = await getUser(userId);

			if (!result.ok) {
				alert('Der opstod en fejl');
			} else {
				console.log(result.data);
				setUser(result.data);
				setFirstname(result.data.firstName);
				setLastname(result.data.lastName);
				setEmail(result.data.email);
				setPhone(result.data.phone);

				setLoading(false);
			}
		}

		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleCancel = () => {
		navigate('/administration/org/' + user.org.id + '/edit');
	}

	const handleSave = async () => {
		setLoading(true);

		const newUser = { ...user };
		newUser.firstName = firstname;
		newUser.lastName = lastname;
		newUser.email = email;
		newUser.phone = phone;

		const result = await updateUser(userId, newUser);

		if (!result.ok) {
			alert('Der opstod en fejl');
		} else {
			setLoading(false);

			navigate('/administration/org/' + user.org.id + '/edit');
		}
	}

	const handleDelete = async () => {
		setConfirmOpen(true);
	}

	const handleDeleteDo = async () => {
		setLoading(true);

		const result = await deleteUser(userId);

		if (!result.ok) {
			alert('Der opstod en fejl');
		} else {
			setLoading(false);
			setConfirmOpen(false);

			navigate('/administration/org/' + user.org.id + '/edit');
		}
	}

	return (
		<>
			{!loading ? (
				<>
					<Typography variant="h1">Rediger bruger</Typography>

					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TextField
								id="firstname"
								label="Fornavn"
								variant="outlined"
								value={firstname}
								onChange={(e) => setFirstname(e.target.value)}
								style={{ width: 300 }}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								id="lastname"
								label="Efternavn"
								variant="outlined"
								value={lastname}
								onChange={(e) => setLastname(e.target.value)}
								style={{ width: 300 }}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								id="email"
								label="E-mail"
								variant="outlined"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								style={{ width: 300 }}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								id="phone"
								label="Telefonnummer"
								variant="outlined"
								value={phone}
								onChange={(e) => setPhone(e.target.value)}
								style={{ width: 300 }}
							/>
						</Grid>
						<Grid item xs={12}>
							<ButtonGroup variant="contained" size="large">
								<Button onClick={() => handleCancel()}>ANNULER</Button>
								<Button onClick={() => handleSave()}>GEM</Button>
								<Button onClick={() => handleDelete()}>SLET</Button>
							</ButtonGroup>
						</Grid>
					</Grid>

					<ConfirmDialog
						visible={confirmOpen}
						title="Slet bruger"
						text="Er du sikker på at du vil slette denne bruger?"
						handleOk={() => handleDeleteDo()}
						handleCancel={() => setConfirmOpen(false)}
					/>
				</>
			) : (
				<CircularProgress />
			)}
		</>
	)
}

export default AdministrationUserEdit;