import { useEffect, useState } from "react";
import { CircularProgress, Typography, Button } from "@mui/material"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import { useNavigate } from "react-router-dom";

import useGlobalState from "../hooks/useGlobalState";
import { getOrganisations } from '../lib/api';
import TableHeader from "../components/TableHeader";
import AdministrationOrgUsers from "./AdministrationOrgUsers";
import { getComparator, stableSort } from "../lib/functions";

const Administration = () => {
	const navigate = useNavigate();
	const [state] = useGlobalState();

	const [loading, setLoading] = useState(true);
	const [orgs, setOrgs] = useState([]);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(15);
	const [sortOrder, setSortOrder] = useState('asc');
	const [sortOrderBy, setSortOrderBy] = useState('created');

	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orgs.length) : 0;

	const headerCells = [
		{
			id: 'name',
			numeric: false,
			label: 'Firma',
		},
		{
			id: 'address',
			numeric: false,
			label: 'Addresse',
		},
		{
			id: 'zip',
			numeric: true,
			label: 'Postnummer',
		},
		{
			id: 'city',
			numeric: false,
			label: 'By',
		},
	];

	useEffect(() => {
		async function fetchData() {
			const result = await getOrganisations();

			if (result.ok) {
				let newOrgs = [...result.data];
				newOrgs = newOrgs.filter(org => org.id !== 140890100001721);

				setOrgs(newOrgs);
			}

			setLoading(false);

		}

		fetchData();
	}, []);

	const handleRequestSort = (event, property) => {
		const isAsc = sortOrderBy === property && sortOrder === 'asc';
		setSortOrder(isAsc ? 'desc' : 'asc');
		setSortOrderBy(property);
	}

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	}

	const handleRowClick = (id) => {
		navigate('/administration/org/' + id + '/edit');
	}

	return (
		<>
			<Typography variant="h1">Administration</Typography>

			{state.userIsAdmin ? <Button variant="contained" onClick={() => navigate('/administration/org/add')} style={{ marginBottom: 20 }}>Tilføj firma</Button> : <></>}
			{state.userIsAdmin ? <Button variant="contained" onClick={() => navigate('/administration/importproducts')} style={{ marginBottom: 20 }}>Importer produkter</Button> : <></>}

			{!loading ? (
				<>
					<TableContainer component={Paper}>
						<Table sx={{ minWidth: 650 }}>
							<TableHeader
								order={sortOrder}
								orderBy={sortOrderBy}
								onRequestSort={handleRequestSort}
								headerCells={headerCells}
							/>
							<TableBody>
								{stableSort(orgs, getComparator(sortOrder, sortOrderBy))
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((org) => (
										<TableRow
											hover
											key={org.id}
											onClick={() => handleRowClick(org.id)}
											style={{ cursor: 'pointer' }}
										>
											<TableCell>{org.name}</TableCell>
											<TableCell>{org.address}</TableCell>
											<TableCell>{org.zip}</TableCell>
											<TableCell>{org.city}</TableCell>
										</TableRow>
									))}
								{emptyRows > 0 && (
									<TableRow
										style={{
											height: 53 * emptyRows,
										}}
									>
										<TableCell colSpan={6} />
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						rowsPerPageOptions={[15, 25, 50, 100]}
						component="div"
						count={orgs.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>

					{state.userIsAdmin ? (
						<>
							<AdministrationOrgUsers orgId="140890100001721" title="Brugere uden firma" />
						</>
					) : <></>}
				</>
			) : (
				<CircularProgress />
			)}
		</>
	)
}

export default Administration;