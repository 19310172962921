import { useState } from "react";
import { Typography, Grid, TextField, Button } from "@mui/material";
import cookie from 'react-cookies';
import moment from 'moment';
import { useNavigate } from "react-router-dom";

import { login, getUser } from "../lib/api";
import useGlobalState from "../hooks/useGlobalState";

const Login = () => {
	const navigate = useNavigate();
	const [, dispatch] = useGlobalState();

	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [error, setError] = useState('');

	const handleForgotPassword = () => {
		navigate('/forgotpassword');
	}

	const handleLogin = async () => {
		setError('');

		if (username === '' || password === '') {
			setError('Udfyld venligst brugernavn og password');
		} else {
			const loginResult = await login({ username, password });

			if (!loginResult.ok || loginResult.data === null) {
				setError('Kunne ikke logge ind');
			} else {
				cookie.save('token', loginResult.data.sessionID, { path: '/', expires: moment().add('30', 'days').toDate() });
				cookie.save('SESSION', loginResult.data, { path: '/', expires: moment().add('30', 'days').toDate() });

				const userResult = await getUser(loginResult.data.userID);

				if (!userResult.ok) {
					setError('Der opstid en fejl ved login, kunne ikke hente bruger');
				} else {
					let userIsAdmin = false;
					if (userResult.data.privileges?.bygcom?.bygcomadmin === true) {
						userIsAdmin = true;
					}

					dispatch({ user: userResult.data, orderId: null, userIsAdmin: userIsAdmin, selectedStructure: null, selectedStructureType: null });

					navigate('/calculation');
				}
			}
		}
	}

	return (
		<>
			<Typography variant="h1">Log ind</Typography>

			{error !== '' && <Typography variant="body1" color="error" style={{ marginBottom: 20 }}>{error}</Typography>}

			<Grid container spacing={2}>
				<Grid item xs={12}>
					<TextField
						id="username"
						name="username"
						label="E-mail"
						onChange={(e) => setUsername(e.target.value)}
						onKeyDown={(ev) => {
							if (ev.key === 'Enter') {
								ev.preventDefault();
								if (username !== '' && password !== '') {
									handleLogin();
								}
							}
						}}
						style={{ width: 300 }}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						id="password"
						type="password"
						name="password"
						label="Kodeord"
						onChange={(e) => setPassword(e.target.value)}
						onKeyDown={(ev) => {
							if (ev.key === 'Enter') {
								ev.preventDefault();
								if (username !== '' && password !== '') {
									handleLogin();
								}
							}
						}}
						style={{ width: 300, marginTop: 10 }}
					/>
				</Grid>
				<Grid item xs={12}>
					<Button variant="contained" color="primary" onClick={() => handleLogin()}>Log ind</Button>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="h3">Har du glemt dit kodeord kan du nulstille det nedenfor.</Typography>
					<Button variant="contained" color="primary" onClick={() => handleForgotPassword()}>Glemt kodeord</Button>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="h3" style={{ marginTop: 20 }}>Ønsker du at oprette en bruger kan du gøre det nedenfor.</Typography>
					<Button variant="contained" color="primary" onClick={() => navigate('/createuser')}>Opret bruger</Button>
				</Grid>
			</Grid>
		</>
	)
}

export default Login;