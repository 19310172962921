
import { useState } from "react";
import cookie from 'react-cookies';


const AdministrationImportProduct = () => {
	// const [loading, setLoading] = useState(false);
	const [file, setFile] = useState(null);

	const handleFileChange = (e) => {
		setFile(e.target.files[0]);
	}

	const handleUpload = async () => {
		// setLoading(true);

		const formData = new FormData();
		formData.append('file', file);

		const response = await fetch('https://beregnerbackend.bygcom.com/rest/bygcom/adminProduct', {
			method: 'POST',
			headers: {
				'ODEUMAuthToken': cookie.load('token'),
			},
			body: formData,
		});
		console.log(response);
	}

	return (
		<div>
			<h1>Importer produkter</h1>
			<input type="file" onChange={handleFileChange} />
			<button onClick={handleUpload}>Upload</button>
		</div>
	)
}

export default AdministrationImportProduct