import { useState } from "react";
import { Grid, TextField, Typography, ButtonGroup, Button, FormControl, InputLabel, Select, MenuItem, FormGroup, Checkbox, Box, Modal } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { addUser, getCompanyByCvr } from "../lib/api";

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '100%',
	maxWidth: 1000,
	height: '90vh',
	maxHeight: 800,
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 4,
};

const CreateUser = () => {
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const [success, setSuccess] = useState(false);
	const [firstname, setFirstname] = useState('');
	const [lastname, setLastname] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [cvr, setCvr] = useState('');
	const [companyName, setCompanyName] = useState('');
	const [address, setAddress] = useState('');
	const [zip, setZip] = useState('');
	const [city, setCity] = useState('');
	const [supplier, setSupplier] = useState(140890100001157);
	const [gdprConfirm, setGdprConfirm] = useState(false);
	const [gdprModalOpen, setGdprModalOpen] = useState(false);

	const suppliers = [{ id: 140890100001157, name: 'Bygcom' }];

	const handleCancel = () => {
		navigate('/');
	}

	const handleSave = async () => {
		setError('');

		if (firstname === '' || lastname === '' || email === '') {
			setError('Firnavn, efternavn og e-mail er påkrævet.');

			window.scrollTo(0, 0);
		} else if (!gdprConfirm) {
			setError('Du skal bekræfte vores privatlivspolitik for at oprette en bruger.');
		} else {
			setLoading(true);

			const newUser = {};
			newUser.userName = email;
			newUser.firstName = firstname;
			newUser.lastName = lastname;
			newUser.email = email;
			newUser.phone = phone;
			newUser.cvr = cvr;
			newUser.companyName = companyName;
			newUser.address = address;
			newUser.zip = zip;
			newUser.city = city;
			// newUser.org = { id: 140890100001721 };
			newUser.supplier = supplier;

			const result = await addUser(newUser);

			if (result.status === 400) {
				setLoading(false);

				setError('En bruger med den indtastede e-mail eksisterer allerede');
			} else {
				if (!result.ok) {
					setLoading(false);

					setError('Der opstod en fejl, kontakt Bygcom for hjælp.');
				} else {
					setLoading(false);

					setSuccess(true);
				}
			}
		}
	}

	const handleShowPrivacyPolicy = () => {
		setGdprModalOpen(true);
	}

	const handleGdprModalClose = () => {
		setGdprModalOpen(false);
	}

	const handleGetCompanyByCvr = async () => {
		const newCvr = cvr.replace(' ', '');
		setCvr(newCvr);

		const result = await getCompanyByCvr(newCvr);

		setCompanyName('');
		setEmail('');
		setPhone('');
		setAddress('');
		setZip('');
		setCity('');


		if (result.ok && result.data.orgs?.length) {
			setCompanyName(result.data.orgs[0].name);
			setEmail(result.data.orgs[0].email);
			setPhone(result.data.orgs[0].phone);
			setAddress(result.data.orgs[0].address);
			setZip(result.data.orgs[0].zip);
			setCity(result.data.orgs[0].city);
		}
	}

	return (
		<>
			<Typography variant="h1">Opret bruger</Typography>

			{!success ? (
				<>
					<Typography variant="body1" style={{ marginBottom: 10 }}>For at se tidligere beregninger osv. skal man være logget ind som en bruger. Herunder kan der oprettes en bruger.</Typography>

					<Typography style={{ marginBottom: 30 }}>Ud fra det indtastede CVR nummer hentes tilgængelige informationer fra CVR registeret, de kan tilpasses som ønsket.</Typography>

					{error !== '' && <Typography variant="body1" color="error" style={{ marginBottom: 20 }}>{error}</Typography>}

					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TextField
								id="cvr"
								label="CVR"
								variant="outlined"
								value={cvr}
								onChange={(e) => setCvr(e.target.value)}
								onBlur={() => handleGetCompanyByCvr()}
								style={{ width: 300 }}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								id="firstname"
								label="Fornavn"
								variant="outlined"
								value={firstname}
								onChange={(e) => setFirstname(e.target.value)}
								style={{ width: 300 }}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								id="lastname"
								label="Efternavn"
								variant="outlined"
								value={lastname}
								onChange={(e) => setLastname(e.target.value)}
								style={{ width: 300 }}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								id="email"
								label="E-mail"
								variant="outlined"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								style={{ width: 300 }}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								id="companyName"
								label="Firmanavn"
								variant="outlined"
								value={companyName}
								onChange={(e) => setCompanyName(e.target.value)}
								style={{ width: 300 }}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								id="phone"
								label="Telefonnummer"
								variant="outlined"
								value={phone}
								onChange={(e) => setPhone(e.target.value)}
								style={{ width: 300 }}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								id="address"
								label="Adresse"
								variant="outlined"
								value={address}
								onChange={(e) => setAddress(e.target.value)}
								style={{ width: 300 }}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								id="zip"
								label="Postnummer"
								variant="outlined"
								value={zip}
								onChange={(e) => setZip(e.target.value)}
								style={{ width: 300 }}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								id="city"
								label="By"
								variant="outlined"
								value={city}
								onChange={(e) => setCity(e.target.value)}
								style={{ width: 300 }}
							/>
						</Grid>
						<Grid item xs={12}>
							<FormControl style={{ width: 300 }}>
								<InputLabel id="supplier-select-label">Leverandør</InputLabel>
								<Select
									labelId="supplier-select-label"
									id="supplier-select"
									value={supplier}
									label="Leverandør"
									onChange={(e) => setSupplier(e.target.value)}
								>
									{suppliers.map((s) => (
										<MenuItem key={s.id} value={s.id}>{s.name}</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12}>
							<FormGroup row>
								<Box style={{ display: 'flex' }}>
									<Checkbox checked={gdprConfirm} onChange={(e) => setGdprConfirm(e.target.checked)} name="gdprConfirm" />
									<div style={{ display: 'flex', marginTop: 10 }}>
										<Typography variant="body1">Bekræft vores&nbsp;</Typography>
										<div onClick={() => handleShowPrivacyPolicy()} style={{ cursor: 'pointer', textDecoration: 'underline' }}>privatlivspolitik</div>
									</div>
								</Box>
							</FormGroup>
						</Grid>
						<Grid item xs={12}>
							<ButtonGroup variant="contained" size="large">
								<Button onClick={() => handleCancel()}>ANNULER</Button>
								<Button onClick={() => handleSave()} disabled={loading}>AFSEND</Button>
							</ButtonGroup>
						</Grid>
					</Grid>

					<Modal
						open={gdprModalOpen}
						onClose={handleGdprModalClose}
						aria-labelledby="modal-modal-title"
						aria-describedby="modal-modal-description"
					>
						<Box sx={style}>
							<Typography variant="h2">Privatlivspolitik for bygcom beregner</Typography>
						</Box>
					</Modal>
				</>
			) : (
				<>
					<Typography variant="body1" style={{ marginBottom: 20 }}>Brugeren er oprettet. Du vil inden for kort tid modtage en e-mail, hvor der er et link til godkendelse af din bruger. Før dette er gjort kan du ikke logge ind.</Typography>
				</>
			)}
		</>
	);
}

export default CreateUser;